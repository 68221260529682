// Third party libraries
import _ from 'lodash';

// Models
import SecurityModel from '../../../data/models/security/security';

// Locales
import I18n from '../../../i18n';

export async function reasonBuilder(data) {
  switch (data.action) {
    case 'unlinked':
      const proData = data.pro;

      const proInfo = _.get(await SecurityModel.getProInfo(proData.id), 'data');

      const proPaySubscription = proData.paySubscription;

      return `${I18n.t('refreshSession.reasons.unlinked', {
        name: proInfo.name,
      })} ${
        proPaySubscription
          ? I18n.t('refreshSession.reasons.paySubscription', {
              name: proInfo.name,
            })
          : ''
      }`;

    default:
      break;
  }
}
