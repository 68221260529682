// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// Components
import OButton from '../../styled/components/button';

// Redux store
import { openLoader, closeLoader } from '../../../redux-store/loader';

// Locales
import I18n from '../../../i18n';

class InAppPurchase extends React.Component {

    onClick = () => {

        const { onSuccess, onError, product, openLoader, payer } = this.props;

        openLoader();

        if (!window.store) {

            console.error('Store not loaded correctly');
            onError();

        } else {

            try {

                window.store.applicationUsername = payer;

                if (!_.find(window.store.products, ({ id }) => id === product)) {

                    window.store.register({ type: window.store.CONSUMABLE, id: product });
                    window.store.refresh();

                }

                window.store.once(product).approved(p => {

                    const { transaction: { id, receipt, signature, appStoreReceipt, original_transaction_id } } = p; // IMPORTANT: Extract data before finish, due next the object mute.
                    p.finish();
                    onSuccess(window.device?.platform === 'iOS' ? appStoreReceipt : receipt, id, window.device?.platform === 'iOS' ? original_transaction_id : signature);

                });

                window.store.once(product).cancelled(() => {

                    console.error('Operation cancelled by user');
                    onError();

                });

                window.store.once(product).error(err => {

                    console.error(err);
                    onError();

                });

                window.store.order(product);

            } catch (err) {

                console.error(err);
                onError();

            }

        }

    }

    render() {

        const { text } = this.props;

        return (
            <OButton
                upper
                className="item-btn"
                fluid
                primary
                icon
                size="tiny"
                color='#fff'
                type="button"
                labelPosition='left'
                onClick={ this.onClick }
            >
                <span>
                    { text || I18n.t('payment.pay') }
                </span>
            </OButton>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(InAppPurchase);