import React from 'react';
import I18n from '../../../i18n';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import Logo1 from '../../assets/img/gc.jpg';
import Logo2 from '../../assets/img/cae.jpg';
import Logo3 from '../../assets/img/ue.jpg';

import './footer.scss';
import config from '../../../config';

class Footer extends React.Component {

    render() {

        return (
            <React.Fragment>
                <div className={"footer-section " + this.props.type || ""}>
                    <div className="footer-content">
                        <div className="content">
                            <div className="footer-row">
                                <p><Link to="/legal">{I18n.t('footer.legalAdvice')}</Link></p>
                                <p><Link to="/legal/privacy">{I18n.t('footer.privacy')}</Link></p>
                            </div>
                            <div className="footer-row">
                                <p><Link to="/legal/tos">{I18n.t('footer.tos')}</Link></p>
                                <p><Link to="/faq">{I18n.t('footer.faq')}</Link></p>
                            </div>
                            {localStorage.getItem('jwt') && <div className="footer-row">
                                <p><Link to="/guide">{I18n.t('footer.useGuide')}</Link></p>
                            </div>}
                            <div className="footer-row expanded">
                                <p>
                                    <Trans i18nKey="footer.info">
                                        &#174;
                                    </Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                    {!window.cordova && <div className="footer-extra">
                        <div className="footer-extra-content">
                            <div className="footer-exta-flex-wrap">
                                <div className="f-text">
                                    <Trans i18nKey="footer.infoSecondary">
                                        <b></b><b></b><b></b>&#174;
                                    </Trans>
                                </div>
                                <div className="f-images">
                                    <div><img src={Logo1} alt="" /></div>
                                    <div><img src={Logo2} alt="" /></div>
                                    <div><img src={Logo3} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </React.Fragment>
        );

    }
    
};

export default Footer;