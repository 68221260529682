// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Radio, Form, Responsive } from 'semantic-ui-react';

// Components
import Footer from '../../../components/footer';
import MenuProfile from '../../../components/menu/profile';
import PlansBlocks from '../../../components/plansBlocks';
import TopBar from '../../../components/navbar/topbar';
import SideBarProFileHoc from '../../../components/sidebar/profile';

// Styled components
import OButton from '../../../styled/components/button';

// Utils
import Utils from '../../../../utils';
import { getSubscriptionPrices, hasMonthSubscriptionPromotion, hasYearSubscriptionPromotion } from '../../../../utils/checkPromotions';
import { TrackingService } from '../../../../utils/TrackingService';

// Redux
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import { getRole } from '../../../../redux-store/role';

// Models
import PaymentModel from '../../../../data/models/payment/payment';

// Config
import Config from '../../../../config';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../profile_new.scss';

class UserPlans extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            quote: 'annualPayment',
            hideButton: false
        }

        this.scrollParent = React.createRef();
        this.button = React.createRef();
        this.edge = React.createRef();

    }

    handleChange = quote => this.setState({ quote })

    trackScrolling = e => {

        if (!this.button.current) {

            return;

        }

        if (this.scrollParent.current.scrollTop + this.scrollParent.current.clientHeight >= this.scrollParent.current.scrollHeight - 220) {

            this.button.current.style.display = 'none';

        } else {

            this.button.current.style.display = 'block';

        }

    }

    onContinue = () => {

        const description = 'Producto Premium';
        if (!window.cordova) {

            const price = getSubscriptionPrices()[this.state.quote];
            const periodic = true;
            const data = window.btoa(JSON.stringify(price) + '/' + description + '/' + this.state.quote + '/' + periodic);
            this.props.history.push(`/payment/${data}`);

        } else {

            this.props.openLoader();

            if (!window.store) {

                console.error('Store not loaded correctly');

            } else {

                try {

                    window.store.applicationUsername = this.props.payer;

                    if (!_.find(window.store.products, ({ id }) => id === Config.inapppurchase.premiumSubscription[this.state.quote].id)) {

                        window.store.register({ type: window.store.PAID_SUBSCRIPTION, id: Config.inapppurchase.premiumSubscription[this.state.quote].id });
                        window.store.refresh();

                    }

                    window.store.once(Config.inapppurchase.premiumSubscription[this.state.quote].id).approved(async p => {

                        const { transaction: { id, receipt, signature, appStoreReceipt, original_transaction_id } } = p; // IMPORTANT: Extract data before finish, due next the object mute.

                        p.finish();

                        await PaymentModel.createSubscription({
                            provider: window.device?.platform,
                            orderId: id,
                            receipt: window.device?.platform === 'iOS' ? appStoreReceipt : receipt,
                            signature: window.device?.platform === 'iOS' ? original_transaction_id : signature,
                            type: this.state.quote
                        });

                        await PaymentModel.createUserPayment({
                            amount: Config.inapppurchase.premiumSubscription[this.state.quote].price[this.props.currency][window.device?.platform],
                            currency: this.props.currency,
                            type: this.state.quote,
                            description,
                            platform: window.device?.platform,
                            platformReceipt: window.device?.platform === 'iOS' ? appStoreReceipt : receipt,
                            orderId: id,
                            signature: window.device?.platform === 'iOS' ? original_transaction_id : signature
                        });

                        const affiliation = window.device?.platform === 'Android' ? 'Google Play' : 'Apple Store';

                        TrackingService.registerEvent('Purchase', 'purchase', {
                            affiliation,
                            currency: this.props.currency,
                            value: Config.inapppurchase.premiumSubscription[this.state.quote].price[this.props.currency][window.device?.platform],
                            item_list_name: `Suscripción premium ${ this.state.quote === 'annualPayment' ? 'anual' : 'mensual' }`
                        });

                        this.props.getRole();
                        this.props.closeLoader();
                        this.props.history.push(`/profile/suscription`);

                    });

                    window.store.once(Config.inapppurchase.premiumSubscription[this.state.quote].id).cancelled(() => {

                        console.error('Operation cancelled by user');
                        this.props.closeLoader();
                        this.props.history.push(`/auth/payment/incorrect`);

                    });

                    window.store.once(Config.inapppurchase.premiumSubscription[this.state.quote].id).error(err => {

                        console.error(err);
                        this.props.closeLoader();
                        this.props.history.push(`/auth/payment/incorrect`);

                    });

                    window.store.order(Config.inapppurchase.premiumSubscription[this.state.quote].id);

                } catch (err) {

                    console.error(err);
                    this.props.closeLoader();
                    this.props.history.push(`/auth/payment/incorrect`);

                }

            }

        }

    }

    gotoEnd = () => this.scrollParent.current.scrollTo(0, this.edge.current.offsetTop);

    render() {

        //TODO añadir literales

        const { quote } = this.state;
        const { currency } = this.props;
        const annualPaymentWithoutDiscount = !window.cordova ? { USD: 9.99 * 12, EUR: 8.99 * 12 } : { USD: Config.inapppurchase.premiumSubscription.monthlyPayment.price.USD[window.device?.platform] * 12, EUR: Config.inapppurchase.premiumSubscription.monthlyPayment.price.EUR[window.device?.platform] * 12 };

        return (
            <React.Fragment>
                <div className="p-wrapper" ref={this.scrollParent} onScroll={this.trackScrolling}>
                    <TopBar {...this.props} menuCallback={()=> this.props.onToggle()} showProfile={false} text={I18n.t('profile.plans')} invert={true}/>
                    <div className="inner">
                        <div className="p-flex">
                            <div className="p-aside">
                                <MenuProfile active="plans" {...this.props} />
                            </div>
                            <div className="p-content">
                                <div className="plans-grid">
                                    <div className="item">
                                        <div className="title"><p>Disfruta Yogabot PREMIUM por todo un año, sin límites. <span className="cbrand">Por tan sólo { Utils.getAmountLiteral(getSubscriptionPrices().annualPayment[currency], currency) } al año</span></p></div>
                                        { !!window.cordova && <span style={{ fontSize: '12px', color: 'red' }}>{ I18n.t(`${ window.device?.platform }.webDifferentPricesAdvise`) }&reg;</span> }
                                        <PlansBlocks showHeader={false}/>
                                    </div>
                                    <div ref={this.edge}></div>
                                    <div className="item">
                                        <div className="floating-cta">
                                            <div className="content" >
                                                <p className="title-cta" >Selecciona el tipo de cuota</p>
                                                <div className="cta-plans">
                                                    <div className={ 'plan ' + (hasMonthSubscriptionPromotion() ? 'ov-hidden ' : '') + (quote == 'monthlyPayment' ?  'active' : '') } onClick={() => this.handleChange('monthlyPayment')}>
                                                        {hasMonthSubscriptionPromotion() && <div className="offer-corner">
                                                            <div className="text">PROMO</div>
                                                        </div>}
                                                        <Form>
                                                            <Form.Field inline>
                                                                <Radio
                                                                    label=''
                                                                    name='radio-options'
                                                                    value='monthlyPayment'
                                                                    checked={ quote === 'monthlyPayment' }
                                                                />
                                                            </Form.Field>
                                                        </Form>
                                                        <span>Mensual:</span>
                                                        <span className={hasMonthSubscriptionPromotion() ? "strikethrough" : ""}>{ Utils.getAmountLiteral(getSubscriptionPrices().monthlyPayment[currency], currency) }</span>
                                                        {hasMonthSubscriptionPromotion() && <span>{ Utils.getAmountLiteral(getSubscriptionPrices().monthlyPayment[currency], currency) }</span>}
                                                        <span>Puedes cancelar en cualquier momento.</span>
                                                    </div>
                                                    <div data-name={ hasYearSubscriptionPromotion() ? '' : I18n.t('profile.morePopular') } className={ 'plan with-ribbon ' + (quote == 'annualPayment' ?  'active' : '') } onClick={() => this.handleChange('annualPayment')}>
                                                        { hasYearSubscriptionPromotion() && <div className="offer-corner">
                                                            <div className="text">PROMO</div>
                                                        </div>}
                                                        <Form>
                                                            <Form.Field inline>
                                                                <Radio
                                                                    label=''
                                                                    name='radio-options'
                                                                    value='annualPayment'
                                                                    checked={ quote === 'annualPayment' }
                                                                />
                                                            </Form.Field>
                                                        </Form>
                                                        <span>Anual:</span>
                                                        <span className="strikethrough">{ Utils.getAmountLiteral(annualPaymentWithoutDiscount[currency], currency) }</span>
                                                        <span className="poppins-bold upper">sólo { Utils.getAmountLiteral(getSubscriptionPrices().annualPayment[currency], currency) }</span>
                                                        <i className="cbrand">Ahorra { 100 - _.round(getSubscriptionPrices().annualPayment[currency] / annualPaymentWithoutDiscount[currency] * 100) }%</i>
                                                    </div>
                                                </div>
                                                <OButton primary type="button" fluid upper color="#fff" onClick={this.onContinue}>
                                                    <span>{I18n.t('actions.suscribe')}</span>
                                                </OButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Responsive maxWidth={420}>
                            <span className="plans-btn-sticky" ref={this.button} onClick={this.gotoEnd}>continuar</span>
                        </Responsive>
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {})
    };

};

const Wrapped = SideBarProFileHoc(connect(mapStateToProps, { openLoader, closeLoader, getRole })(UserPlans), 'plans');

export default Wrapped;
