// Third party libraries
import _ from 'lodash';
import { connect } from 'react-redux';

// Redux
import { closeGenericModal, openGenericModal } from '../../../redux-store/genericModal';

// Models
import SecurityModel from '../../../data/models/security/security';

// Locales
import I18n from '../../../i18n';

const InAppFirebaseNotification = props => {

    if (window.FirebasePlugin) {

        window.FirebasePlugin.grantPermission(hasPermission => {

            if (hasPermission) {

                window.FirebasePlugin.getToken(notificationToken => {

                    if (notificationToken) {

                        if (!_.find(props.notificationTokens, ({ token }) => token === notificationToken)) {

                            SecurityModel.insertUserNotificationToken({ token: notificationToken, platform: window.device?.platform });

                        }

                    }

                });

                window.FirebasePlugin.onMessageReceived(payload => {

                    if (!payload.tap) {

                        props.openGenericModal({
                            type: 'simple',
                            title: {
                                text: payload.title,
                                classes: ['heading-2']
                            },
                            description: {
                                text: payload.body,
                                classes: ['paragraph', 'regular']
                            },
                            buttons: [{
                                text: I18n.t('actions.understood'),
                                callback: props.closeGenericModal,
                                options: {
                                    primary: true,
                                    color: '#fff',
                                    fluid: true,
                                    upper: true
                                }
                            }],
                            image: payload.image,
                            scrolling: true
                        });

                    }

                });

            }

        });

    }

    return null;

}

export default connect(null, { closeGenericModal, openGenericModal })(InAppFirebaseNotification);