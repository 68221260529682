// Third party libraries
import _ from 'lodash';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import Moment from 'moment';

// Config
import Config from '../config';

// Models
import SecurityModel from '../data/models/security/security';

const firebase = Firebase.initializeApp(Config.firebase);

const googleAuthProvider = new Firebase.auth.GoogleAuthProvider();
googleAuthProvider.addScope('email');
const facebookAuthProvider = new Firebase.auth.FacebookAuthProvider();
facebookAuthProvider.addScope('email');

const loginObject = {
    expiresIn: Moment().add(1, 'days').format(),
    lastName: ""
};

const getFirebaseObject = () => firebase;

const askForNotificationsPermission = async notificationTokens => {

    try {

        if (!window.cordova) {

            const messaging = firebase.messaging();
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {

                const notificationToken = await messaging.getToken();

                messaging.onMessage(payload => {

                    const data = payload.notification;
                    new Notification(data.title, {
                        body: data.body,
                        image: data.image,
                        icon: 'https://yogabot.app/assets/img/favicon/apple-touch-icon.png'
                    });

                });

                if (!_.find(notificationTokens, ({ token }) => token === notificationToken)) {

                    SecurityModel.insertUserNotificationToken({ token: notificationToken, platform: 'Web' });

                }

            }

        }

    } catch (error) {

        console.error(error);

    }

};

const firebaseThirdPartyLogin = async (provider, callback) => {

    loginObject.provider = provider;

    if (!!window.cordova) {

        await firebase.auth().signInWithRedirect(provider === 'facebook' ? facebookAuthProvider : googleAuthProvider).then(() => firebase.auth().getRedirectResult()).then(({user}) => {

            loginObject.email = user.providerData[0].email;
            loginObject.firstName = user.providerData[0].displayName;
            loginObject.pictureUrl = user.providerData[0].photoURL;
            loginObject.thirdPartyId = user.providerData[0].uid;

            callback(loginObject);

        }).catch(error => {

            console.error(error.message)

        });

    } else {

        await firebase.auth().signInWithPopup(provider === 'facebook' ? facebookAuthProvider : googleAuthProvider).then(({user}) => {

            loginObject.email = user.providerData[0].email;
            loginObject.firstName = user.providerData[0].displayName;
            loginObject.pictureUrl = user.providerData[0].photoURL;
            loginObject.thirdPartyId = user.providerData[0].uid;

            callback(loginObject);

        }).catch(error => {

            console.error(error)

        });

    }

};

export {
    askForNotificationsPermission,
    firebaseThirdPartyLogin,
    getFirebaseObject
};