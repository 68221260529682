import React, { Component } from 'react'
import { LandingHeader } from './components/header';
import TestimonialSwiper from './components/testimonialSlider';

import {
    InnerSection,
    Section,
    SectionSubHeaderLogo,
    SectionSubHeaderCta,
    CtaLogin,
    CtaRegister,
    SectionHeroText,
    SectionHeroImage,
    MarketsLogos,
    FeaturesGrid,
    FeaturesGridItem,
    SectionLegacyContent,
    SectionLegacyImage,
    SectionGrid,
    SectionGridItemText,
    SectionGridItemImage,
    ButtonWrapper,
    LegacyPoints,
    LegacyPoint,
    LegacyPointIcon,
    LandingWrap,
    OverflowEd,
    ButtonChallengeWrapper,
    AuxSeparator
} from './styled';

import GooglePlayIcon from '../../../../assets/img/m_google.png';
import AppleStoreIcon from '../../../../assets/img/m_apple.png';

import F1 from '../../../../assets/img/f1.png';
import F2 from '../../../../assets/img/f2.png';
import F3 from '../../../../assets/img/f3.png';
import F4 from '../../../../assets/img/f4.png';

import LegacySectionImage from '../../../../assets/img/main_landing/legacy.svg';
import HeroSectionImage from '../../../../assets/img/main_landing/hero.svg';
import MatrixSectionImage from '../../../../assets/img/main_landing/matriz.svg';
import PracticeSectionImage from '../../../../assets/img/main_landing/practicas.png';
import TechnicSectionImage from '../../../../assets/img/main_landing/tecnica.svg';
import RoadmapSectionImage from '../../../../assets/img/main_landing/roadmap.png';

import OButton from '../../../../styled/components/button';
import { Icon } from 'semantic-ui-react';
import { SectionPrices } from './components/prices';
import { SectionChallenge } from './components/sectionChallenge';
import Footer from '../../../../components/footer';
import CookiesPopup from '../../../../components/cookiesPopup';
import { motion } from 'framer-motion';
import { CtaMarkets } from './components/ctaMarket';
import { motionSubtitle, motionTitle, transition  } from '../../../../../utils/framerAnimations';
import { FaqAccordion } from './components/faq';

class MainLanding extends Component {
    render() {
        return (
            <LandingWrap>
               <LandingHeader active="home" />
                <OverflowEd>
                    <Section>
                        <InnerSection sectionSubHeader>
                            <SectionSubHeaderLogo />
                            <SectionSubHeaderCta>
                                    <CtaLogin to="/auth/login">Inicia sesión</CtaLogin>
                                    <CtaRegister to="/auth/register">Prueba gratis</CtaRegister>
                            </SectionSubHeaderCta>
                        </InnerSection>
                    </Section>
                    <Section >
                        <InnerSection sectionHero>
                            <SectionHeroText>
                                <motion.div initial="initial" animate="animate" exit='exit' >
                                        <motion.h1 variants={motionTitle} className="landing-heading-1"> Recupera tu <br/>equilibrio con Yogabot.</motion.h1>
                                        <motion.p variants={motionSubtitle} className="landing-heading-2 regular">La app de Yoga que se adapta a tu cuerpo y avanza contigo.</motion.p>
                                </motion.div>
                            </SectionHeroText>
                            <SectionHeroImage >
                                <motion.img src={HeroSectionImage} alt="" initial={{ y: 20, opacity: 0 }} animate = {{y: 0,opacity: 1, transition: transition}} />
                            </SectionHeroImage>
                        </InnerSection>
                    </Section>
                </OverflowEd>
                <Section>
                    <InnerSection sectionMarket>
                        <p className="landing-heading-2 regular title">Prueba GRATIS Yogabot Premium y descubre hasta dónde puedes llegar con una práctica de yoga a tu medida.</p>
                        <p className="paragraph regular description">Disponible en:</p>
                        <MarketsLogos >
                            <a href="https://play.google.com/store/apps/details?id=app.yogabot" target="_blank" rel="noopener noreferrer">
                                <motion.img src={GooglePlayIcon} alt="google play" whileHover={{ scale: 1.1 }} />
                            </a>
                            <a href="https://apps.apple.com/us/app/id1521225922" target="_blank" rel="noopener noreferrer">
                                <motion.img src={AppleStoreIcon} alt="apple store" whileHover={{ scale: 1.1 }} />
                            </a>
                        </MarketsLogos>
                    </InnerSection>
               </Section>
               <Section>
                    <InnerSection sectionLegacy>
                        <SectionLegacyImage>
                            <img src={LegacySectionImage} alt="" />
                        </SectionLegacyImage>
                        <SectionLegacyContent>
                            <p className="paragraph ">¿Sabes por qué muchas de las personas que se inician en el yoga se dan por vencidas tras la primera semana?</p>
                            <p className="paragraph regular">Porque las clases pensadas para cualquiera, en realidad, no le sirven a (casi) nadie.</p>
                            <LegacyPoints>
                                <LegacyPoint>
                                    <LegacyPointIcon>
                                        <Icon name='close' />
                                    </LegacyPointIcon>
                                    <p className="paragraph regular">Generan frustación generalizada.</p>
                                </LegacyPoint>
                                <LegacyPoint>
                                    <LegacyPointIcon>
                                        <Icon name='close' />
                                    </LegacyPointIcon>
                                    <p className="paragraph regular">Refuerzan desequilibrios ya existentes.</p>
                                </LegacyPoint>
                                <LegacyPoint>
                                    <LegacyPointIcon>
                                        <Icon name='close' />
                                    </LegacyPointIcon>
                                    <p className="paragraph regular">Pasan por alto el estado físico del practicante.</p>
                                </LegacyPoint>
                            </LegacyPoints>
                            <p className="paragraph regular">Diferenciar entre nivel principiante, inntermedio o avanzado nunca es suficiente, <strong>¿verdad?</strong></p>
                        </SectionLegacyContent>
                    </InnerSection>
               </Section>
               <Section>
                    <InnerSection sectionFeatures>
                        <p className="landing-heading-2 title">POR FIN UNA APP PARA EL SIGLO XXI.</p>
                        <div className="description">
                            <p className="paragraph regular">Ahora imagina que un profesor de yoga crea <strong>una secuencia de asanas solo para ti</strong> después de analizar hasta <strong>40 parámetros biométricos</strong> sobre tu elasticidad, fuerza y tono muscular.</p>
                            <p className="paragraph regular">Y que ese profesor no solo diseña una secuencia de práctica optimizada para ti, sino que también:</p>
                        </div>
                        <FeaturesGrid>
                            <FeaturesGridItem>
                                <img src={F1} alt="calendar" />
                                <p className="paragraph regular">Adapta tu secuencia al tiempo real que dispones para practicar, en cada momento.</p>
                            </FeaturesGridItem>
                            <FeaturesGridItem>
                                <img src={F2} alt="calendar" />
                                <p className="paragraph regular">Te acompaña durante tu sesión de práctica, respetando tu nivel y los tiempos de permanencia.</p>
                            </FeaturesGridItem>
                            <FeaturesGridItem>
                                <img src={F3} alt="calendar" />
                                <p className="paragraph regular">Lleva un registro de tu evolución y optimiza las nuevas prácticas, a tu medida.</p>
                            </FeaturesGridItem>
                            <FeaturesGridItem>
                                <img src={F4} alt="calendar" />
                                <p className="paragraph regular">Te ofrece secuencias alternativas para acelerar tu crecimiento en Yoga.</p>
                            </FeaturesGridItem>
                        </FeaturesGrid>
                    </InnerSection>
               </Section>
               <Section>
                    <InnerSection sectionGrid>
                        <p className="landing-heading-2 regular title">Todo esto es lo que ofrece Yogabot, pero con una ventaja:<br/><strong>Tú decides cuándo, dónde y cómo.</strong></p>
                        <SectionGrid reverse>
                            <SectionGridItemText reverse>
                                <p className="landing-heading-2">MATRIZ BIOMÉTRICA</p>
                                <p className="paragraph regular">Obtén tu mapa personalizado indicando la elasticidad, tono, potencia y nivel de equilibrio de cada zona de tu cuerpo.</p>
                                <ButtonWrapper reverse>
                                    <OButton upper type="button" color="#FFF" primary size="large" onClick={()=> this.props.history.push('/landing/biometricMatrix')}>
                                        <span className="toregular">QUIERO SABER MÁS</span>
                                    </OButton>
                                </ButtonWrapper>
                            </SectionGridItemText>
                            <SectionGridItemImage reverse>
                                <img src={MatrixSectionImage} alt="" />
                            </SectionGridItemImage>
                        </SectionGrid>
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection sectionGrid>
                        <SectionGrid>
                            <SectionGridItemImage>
                                <img src={PracticeSectionImage} alt="" />
                            </SectionGridItemImage>
                            <SectionGridItemText>
                                <p className="landing-heading-2">PRÁCTICAS PROFESIONALES</p>
                                <p className="paragraph regular">Practica secuencias de Yoga <strong>100% óptimas y personalizadas para ti</strong>, según tu Matriz Biométrica. Y disfrutas de prácticas alternativas para situaciones y objetivos específicos.</p>
                                <ButtonWrapper>
                                    <OButton upper type="button" color="#FFF" primary size="large" onClick={()=> this.props.history.push('/landing/practices')}>
                                        <span className="toregular">QUIERO SABER MÁS</span>
                                    </OButton>
                                </ButtonWrapper>
                            </SectionGridItemText>
                        </SectionGrid>
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection sectionGrid>
                        <SectionGrid reverse>
                            <SectionGridItemText reverse>
                                <p className="landing-heading-2">PERFECCIONA TU TÉCNICA</p>
                                <p className="paragraph regular">Perfecciona y enriquece tu práctica de ASANA. Estudia y domina la técnica de cada postura con los <strong>vídeos de ejecución técnica</strong>. A tu ritmo.</p>
                                <ButtonWrapper reverse>
                                    <OButton upper type="button" color="#FFF" primary size="large" onClick={()=> this.props.history.push('/landing/studio')}>
                                        <span className="toregular">QUIERO SABER MÁS</span>
                                    </OButton>
                                </ButtonWrapper>
                            </SectionGridItemText>
                            <SectionGridItemImage reverse>
                                <img src={TechnicSectionImage} alt="" />
                            </SectionGridItemImage>
                        </SectionGrid>
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection sectionPrices className="section-prices">
                        <p className="landing-heading-2 title">NUESTROS PRECIOS</p>
                        <SectionPrices {...this.props}/>
                        <ButtonChallengeWrapper >
                            <OButton upper type="button" color="#FFF" primary size="large" onClick={() => this.props.history.push('/landing/plans')}>
                                <span className="toregular">QUIERO SABER MÁS</span>
                            </OButton>
                        </ButtonChallengeWrapper>
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection sectionChallenge>
                        <p className="landing-heading-2 title">RETO DE INICIACIÓN: MAXIMIZA TU EXPERIENCIA EN 30 DÍAS</p>
                        <p className="landing-heading-3 description regular">Para que puedas obtener el máximo beneficio de Yogabot, hemos creado el “Modo Crecimiento”. ¿Cómo funciona?</p>
                        <SectionChallenge />
                        <p className="landing-heading-2 subtitle">Nada que perder y mucho por experimentar.</p>
                        <ButtonChallengeWrapper >
                            <OButton upper type="button" color="#FFF" primary size="large" onClick={()=> this.props.history.push('/auth/register')}>
                                <span className="toregular">CREA TU CUENTA EN YOGABOT</span>
                            </OButton>
                        </ButtonChallengeWrapper>
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection sectionGrid>
                        <SectionGrid reverse>
                            <SectionGridItemText reverse>
                                <p className="landing-heading-2">ROADMAP</p>
                                <p className="paragraph regular">Mantente al tanto de la evolución del Proyecto Yogabot y <strong>forma parte del futuro del Yoga.</strong></p>
                                <ButtonWrapper reverse>
                                    <OButton upper type="button" color="#FFF" primary size="large">
                                        <span className="toregular">próximamente</span>
                                    </OButton>
                                </ButtonWrapper>
                            </SectionGridItemText>
                            <SectionGridItemImage reverse>
                                <img src={RoadmapSectionImage} alt="" />
                            </SectionGridItemImage>
                        </SectionGrid>
                    </InnerSection>
                </Section>
               <Section>
                    <InnerSection className="testimonials" sectionTestimonials>
                        <p className="landing-heading-2 title">QUÉ DICEN DE NOSOTROS</p>
                        <TestimonialSwiper />
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection >
                        <p className="landing-heading-2 title">Preguntas frecuentes</p>
                        <FaqAccordion />
                    </InnerSection>
                </Section>
                <AuxSeparator />
                <CtaMarkets />
                <Footer {...this.props} type="branded" hasExtraFooter={true}/>
                <CookiesPopup type="alternative" {...this.props}/>
            </LandingWrap>
        )
    }
}

export default MainLanding;