// Third party libraries
import { connect, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

// Redux
import {
  closeGenericModal,
  openGenericModal,
} from '../../../redux-store/genericModal';
import { getProfile } from '../../../redux-store/auth';
import { getChallenges } from '../../../redux-store/challenges';
import { getBiometricMatrix } from '../../../redux-store/bioMetricMatrix';
import { getLastWeekTrainingSeconds } from '../../../redux-store/session';
import { getRole } from '../../../redux-store/role';
import { getAlternativePractices } from '../../../redux-store/alternativePractices';
import { getMarketplace } from '../../../redux-store/cart';
import { getCustomSessionStats } from '../../../redux-store/customSessionStats';
import { getTransactionMovements } from '../../../redux-store/walletTransactions';

// Utils
import { reasonBuilder } from './reasonsBuilder';

// Locales
import I18n from '../../../i18n';

// Config
import config from '../../../config';

const RefreshSession = (props) => {
  const dispatch = useDispatch();

  const userSession = useSelector((state) => state.auth.userSession);

  const loadUserData = useCallback(async () => {
  
        dispatch(getProfile());
        dispatch(getChallenges());
        dispatch(getBiometricMatrix());
        dispatch(getLastWeekTrainingSeconds());
        dispatch(getRole());
        dispatch(getAlternativePractices());
        dispatch(getMarketplace());
        dispatch(getCustomSessionStats());
        dispatch(getTransactionMovements());
  
  }, [dispatch])

  useEffect(() => {
    if (!userSession?._id) return;

    const token = window.localStorage.getItem('jwt');

    if(!token) return;

    const eventSource = new EventSource(
      `${config.server.api.securityApi}refresh-session?x-access-token=${token}`
    );

    eventSource.onmessage = async (event) => {
      const message = JSON.parse(event.data);

      const reason = await reasonBuilder(message.data);

      if (!reason) return;

      await loadUserData();

      props.openGenericModal({
        type: 'simple',
        title: {
          text: I18n.t('refreshSession.title'),
          classes: ['heading-2'],
        },
        description: {
          text: I18n.t('refreshSession.reasons.title') + reason,
          classes: ['paragraph', 'regular'],
        },
        buttons: [
          {
            text: I18n.t('actions.understood'),
            callback: props.closeGenericModal,
            options: {
              primary: true,
              color: '#fff',
              fluid: true,
              upper: true,
            },
          },
        ],
        scrolling: true,
      });
    };

    eventSource.onerror = () => {
      console.error('Error en la conexión SSE');
      eventSource.close();
    };

    return () => {
      eventSource.close(); // Cerrar conexión al desmontar el componente
    };
  }, [loadUserData, props, userSession]);

  return null;
};

export default connect(null, { closeGenericModal, openGenericModal })(
  RefreshSession
);
