export function redirectToApp(path) {
  var android_app_url = 'yogabot:/' + path; // o https://yogabot.app/rutaCompleta
  var ios_app_url = 'yogabot:/' + path;

  if (navigator.userAgent.match(/android/i)) {
    window.location.href = android_app_url;
  } else if (navigator.userAgent.match(/iphone|ipad|ipod/i)) {
    window.location.href = ios_app_url;
  } else {
    return;
  }
}