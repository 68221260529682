// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize, autofill  } from 'redux-form';
import { Form } from 'semantic-ui-react';
import moment from 'moment';

// Components
import sideBarProFileHoc from '../../../components/sidebar/profile';
import Footer from '../../../components/footer';
import CustomInput from '../../../components/form/input';
import CustomSelect from '../../../components/form/select';
import Notification from '../../../components/notificaction';
import FormChangeEmail from '../components/formChangeEmail';
import ErrorMessage from '../../../components/errorMessage';
import PromoBlock from '../../../components/promoBlock';
import TopBar from '../../../components/navbar/topbar';
import MenuProfile from '../../../components/menu/profile';
import InputFile from '../../../components/form/inputFile';

// Styled components
import OButton from '../../../styled/components/button';
import { LabelForm, Picture, PictureContent } from './styles';

// Redux
import { openToast } from '../../../../redux-store/toast';
import { updateProfile } from '../../../../redux-store/auth';
import { getRole } from '../../../../redux-store/role';
import { closeGenericModal, openGenericModal } from '../../../../redux-store/genericModal';

// Models
import SecurityModel from '../../../../data/models/security/security';
import FilesModel from '../../../../data/models/files';

// Constants
import { countries }  from '../../../../config/constants/countries';
import Config from '../../../../config';

// Services
import { TrackingService } from '../../../../utils/TrackingService';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../profile_new.scss';

// Assets
import DemoImage from '../../../assets/img/yogabot_icon.svg';
import config from '../../../../config';

class UserAccount extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            removedAccountMessage : false,
            codeError: -1,
            modifyEmail: false,
            modifiyEmailCorrect: false,
            hasError: false,
            options : [
                {
                    key: 1,
                    value: 1,
                    text: I18n.t('profile.male')
                },
                {
                    key: 2,
                    value: 2,
                    text: I18n.t('profile.female')
                }
            ],
            optionsCurrency : [
                {
                    key: 1,
                    value: 'EUR',
                    text: I18n.t('profile.euro')
                },
                {
                    key: 2,
                    value: 'USD',
                    text: I18n.t('profile.dollar')
                }
            ],
            optionsAudio : [
                {
                    key: 1,
                    value: 'NES',
                    text: I18n.t('profile.spanish')
                },
                {
                    key: 2,
                    value: 'SES',
                    text: I18n.t('profile.sanscrito')
                }
            ],
            optionsLevel : [
                {
                    key: 1,
                    value: '01',
                    text: I18n.t('practice.#01')
                },
                {
                    key: 2,
                    value: '00',
                    text: I18n.t('practice.#00')
                }
            ],
            countries : _.cloneDeep(countries),
            imageId: undefined,
        }

    }

    componentDidMount() {

        const initialValues = _.merge({ pictureUrl: '', country: 'es', gender: 2, config: { currency: 'USD', practiceAudioLanguage: 'NES', practiceLevel: '01',  practicePresentationType: 'image', backgroundAudio: 'none' }}, this.props.userSession);
        this.props.dispatch(initialize(
            'profileAccountForm',
            initialValues
        ))

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.userSession, this.props.userSession)) {

            const initialValues = _.merge({ pictureUrl: '', country: 'es', gender: 2, config: { currency: 'USD', practiceAudioLanguage: 'NES', practiceLevel: '01',  practicePresentationType: 'image', backgroundAudio: 'none' }}, this.props.userSession);
            this.props.dispatch(initialize(
                'profileAccountForm',
                initialValues
            ));

        }

    }

    completeGrowChallenges = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('finished');
            await this.props.getRole();

        } catch (error) {

            console.error(error);

        }

    }

    onUpdateProfile = async data => {

        if (!_.isEmpty(window['tempFile']) ) {

            let formData = new FormData();
            let tempData = _.cloneDeep(data);
            formData.append('avatar', window['tempFile'].ref);

            try {

                const response = await FilesModel.uploadImage(formData);

                if (_.get(response,'data.data.url', false)) {

                    tempData.pictureUrl = response.data.data.url;

                }

            } catch (error) {

                delete tempData.pictureUrl;

            } finally {

                this.updateProfile(tempData);

            }

        } else {

            this.updateProfile(data);

        }

        // Ponerle un id random al estado imageId para que se actualice el componente
        const imageId = String(Math.random());
        this.setState({ imageId  });
    }

    updateProfile = async formData => {

        const { role, customSessionStats, bioMetricMatrix } = this.props;
        const { completeGrowChallenges } = this;

        try {

            await this.props.updateProfile({
                name: formData.name,
                surname: formData.surname,
                country: formData.country,
                gender: formData.gender,
                referrerBy: formData.referrerBy,
                pictureUrl: formData.pictureUrl || '',
                config: { ...formData.config }
            });

             //comprobar si está en modo explorado..y que reto tiene completado

             if (_.get(role, 'attributes.growChallenge', false) && role.attributes.growChallenge === 'explorer') {

                //paso 1 completado por defecto
                const completedGrowChallenges = [];
                completedGrowChallenges[0] = 1;

                //check paso 2
                (bioMetricMatrix.progress || 0) >= 100 ? completedGrowChallenges[1] = 2 : completedGrowChallenges[1] = 0;

                //check paso 3
                (customSessionStats.userCustomSessionsCount || 0) >= Config.growChallenge.customPractices ? completedGrowChallenges[2] = 3 : completedGrowChallenges[2] = 0;

                const nextStepToCompleteIndex = completedGrowChallenges.indexOf(0); // possible output -1,0,1,2

                //Si no hay ningún 0, es decir todos completados
                if (nextStepToCompleteIndex === -1 ) {

                    //llamar a finished porque tiene los 3 pasos del modo crecimiento completados
                    completeGrowChallenges();

                }

             }

            openToast({message: I18n.t('messages.dataSaved'), type: 'success'});


        } catch (codeError) {

            openToast({ message: I18n.t(`status.code.${codeError}`), type: 'error' });

        } finally {

            TrackingService.registerEvent('Profile', 'completeProfileForm');

        }

    };

    onRemoveAccount = async () => {

        try {

            await SecurityModel.removeAccount();

        } catch (error) {

            console.error('onRemoveAccount:ko', error);

        } finally {

            this.setState({removedAccountMessage: true});

        }

    }

    onModifyEmail = () => this.setState(prevState => ({modifyEmail: !prevState.modifyEmail}));

    onSaveEmail = async email => {

        try {

            const response = await SecurityModel.changeEmail(email);

            if (response && response.status === 200) {

                this.setState({ modifiyEmailCorrect: true, hasError: false });

            }

        } catch (error) {

            this.setState({ hasError: true, codeError: error, modifiyEmailCorrect: false })

        }

    }

    onRemove = path => {

        this.props.dispatch(autofill('profileAccountForm', path, ''));
        window['tempFile'] = undefined;

    }

    render() {

        const { role, userSession, reduxFormState } = this.props;
        const roles = _.get(role || {}, 'role', []);

        const { removedAccountMessage } = this.state;

        const canShowPromo = () => {

            if (!role.hasActiveSubscription && (!roles.includes('premium') || (role.premiumExpirationDate !== 'NOT_EXPIRE' && moment(role.premiumExpirationDate).startOf('day').diff(moment().startOf('day'), 'days') < 30))) {

                return true;

            }

            return false;

        }

        const optionsType =  [{
            key: 1,
            value: 'image',
            text: "Imagen"
        }, {
            key: 2,
            value: 'video',
            text: "Video",
            disabled: !roles.includes('premium') && !role.hasActiveSubscription
        }];

        const audioOptionsType =  [
        {
            key: 0,
            value: 'none',
            text: "Ninguno"
        },
        {
            key: 1,
            value: 'nature',
            text: "Naturaleza"
        }, {
            key: 2,
            value: 'instructions',
            text: "Instrucciones básicas",
        }];

        const pictureSrc = !_.isEmpty(_.get(window,'tempFile.raw',[])) ? window['tempFile'].raw : !_.isEmpty(reduxFormState.pictureUrl) ? reduxFormState.pictureUrl : DemoImage;

        return (
            <div className="p-wrapper">
                <TopBar {...this.props} menuCallback={()=> this.props.onToggle()} showProfile={false} text={I18n.t('profile.profileEscaped')} invert={true} />
                <div className="inner">
                    <Notification />
                    <div className="p-flex">
                        <div className="p-aside">
                            <MenuProfile key={this.state.imageId} active="account" {...this.props} />
                        </div>
                        <div className="p-content">
                            <div className="a-wrapper">
                                <div className="a-f-wrapper">
                                    <p className="p-section-text">{I18n.t('menu.personalData')}</p>
                                    <div className="verticalPad" style={{position: 'relative'}}>
                                        <div className="left-form-block">
                                            <LabelForm>{I18n.t('auth.email')}</LabelForm>
                                            {this.state.modifyEmail ? <FormChangeEmail onSaveEmail={this.onSaveEmail } initialValues={{email: this.props.userSession.email}} /> : <span className="field-email">{this.props.userSession.email}</span>}
                                        </div>
                                        <div className="right-form-block">
                                            <span className={"icon-change-email " + (this.state.modifyEmail ? 'f-icon-cancel' : 'f-icon-edit')} onClick={this.onModifyEmail}></span>
                                        </div>
                                        <ErrorMessage code={this.state.codeError} active={this.state.hasError} classes={'ui red pointing basic label'}/>
                                        {this.state.modifiyEmailCorrect && <p className="modify-email-correct">{I18n.t('messages.changedEmail')}</p>}
                                        <div className="clear"></div>
                                    </div>
                                    <div className="verticalPad">
                                       <LabelForm>{I18n.t('profile.profilePicture')}</LabelForm>
                                       <PictureContent>
                                            <Picture key={userSession._id}>
                                                <img src={ pictureSrc } alt="" onError={(e)=> e.target.src = DemoImage } />
                                            </Picture>
                                            { (!window.cordova || window.device?.platform === 'Android') &&
                                                <OButton primary type="button" color="#fff" size="small" style={{overflow: 'hidden'}}>
                                                    <span>{_.isEmpty(reduxFormState.pictureUrl) ? I18n.t('actions.addImage') : I18n.t('actions.editImage')}</span>
                                                    <Field
                                                        className="hide-input"
                                                        component={ InputFile }
                                                        name="pictureUrl"
                                                    />
                                                </OButton>
                                            }
                                            { !_.isEmpty(reduxFormState.pictureUrl) &&
                                                <OButton  selected type="button" color="#FF6767" size="small" onClick={ ()=> this.onRemove('pictureUrl')}>
                                                    <span>{I18n.t('actions.remove')}</span>
                                                </OButton>
                                            }
                                        </PictureContent>
                                    </div>
                                    <Form name="profileAccountForm" noValidate onSubmit={this.props.handleSubmit(this.onUpdateProfile)}>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomInput }
                                                placeholder={ I18n.t('profile.name') }
                                                name="name"
                                                fieldClasses="label-primary"
                                                label={ I18n.t('profile.name') } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomInput }
                                                placeholder={ I18n.t('profile.surname') }
                                                name="surname"
                                                fieldClasses="label-primary"
                                                label={ I18n.t('profile.surname') } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='country'
                                                fieldClasses="label-primary"
                                                options={countries}
                                                search={true}
                                                label={ I18n.t("auth.country") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='gender'
                                                fieldClasses="label-primary"
                                                options={this.state.options}
                                                label={ I18n.t("auth.gender") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomInput }
                                                placeholder={ I18n.t('profile.referrerBy') }
                                                name="referrerBy"
                                                fieldClasses="label-primary"
                                                label={ I18n.t('profile.referrerBy') } />
                                        </div>
                                        <p className="p-section-text">{I18n.t('menu.advancedData')}</p>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.currency'
                                                fieldClasses="label-primary"
                                                options={this.state.optionsCurrency}
                                                label={ I18n.t("auth.currency") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.practiceAudioLanguage'
                                                fieldClasses="label-primary"
                                                options={this.state.optionsAudio}
                                                label={ I18n.t("auth.practiceAudioLanguage") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.practiceLevel'
                                                fieldClasses="label-primary"
                                                options={this.state.optionsLevel}
                                                label={ I18n.t("auth.practiceLevel") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.practicePresentationType'
                                                fieldClasses="label-primary"
                                                options={optionsType}
                                                label={ I18n.t("auth.practicePresentationType") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.backgroundAudio'
                                                fieldClasses="label-primary"
                                                options={audioOptionsType}
                                                label={ I18n.t("auth.backgroundAudio") } />
                                        </div>
                                        <div className="verticalPad">
                                        <OButton upper type="submit" color="#FFF" primary>
                                            <span>{I18n.t('actions.saveChanges')}</span>
                                        </OButton>
                                        </div>
                                    </Form>
                                </div>
                                { canShowPromo() && <div className="a-p-wrapper">
                                    <PromoBlock { ...this.props } />
                                </div> }
                            </div>
                            <div className="r-a-wrap">
                                { !removedAccountMessage && <div>
                                    <p>En Yogabot mantenemos un proceso continuo de aprendizaje y optimización de todos nuestros algoritmos de diagnóstico y recomendación. Por tal motivo, para que podamos ofrecerte una <b>experiencia de transformación personal</b> más potente, mediante una práctica de Yoga <b>mucho</b> más óptima y precisa para ti, te recomendamos mantener actualizado los datos de tu Perfil Personal.</p>
                                    <p>En la medida que Yogabot vaya <b>ampliando y perfeccionando</b> sus algoritmos matemáticos de Secuenciación, basados en la Pedagogía NEXOYOGA, se irán agregando nuevos campos al formulario de tu Perfil Personal.</p>
                                    <p>IMPORTANTE: Todos los datos de tu Perfil se utilizan <b>única y exclusivamente</b> para optimizar y personalizar tu experiencia de práctica en Yogabot, y en el momento que decidas ELIMINAR tu cuenta, todos tus datos serán BORRADOS automáticamente de todo el sistema y bases de datos.</p>
                                    <span className="remove-account-btn" onClick={this.onRemoveAccount}>
                                        eliminar tu cuenta
                                    </span>
                                    <div className="clear"></div>
                                </div> }
                                { removedAccountMessage && <div>
                                    <p>Hola {userSession.name || ''} </p>
                                    <p>Gracias por haber utilizado YOGABOT. Es una pena que ya no quieras continuar potenciando tu práctica de Yoga con nosotros, pero respetamos mucho tu decisión.</p>
                                    <p>Te acabamos de enviar un E-mail con un enlace de confirmación para ELIMINAR tu cuenta definitivamente. Por favor, revisa el email y lee las instrucciones que te mandamos.</p>
                                    <p>Namaste</p>
                                </div> }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer { ...this.props } type="branded"/>
            </div>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.referrerBy) && !/^[\d\w_-]+$/gmi.test(formValues.referrerBy)) {

        errors.referrerBy = I18n.t('validations.specialChars');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    return errors;

};

const mapStateToProps = state => {

    return {
        reduxFormState: _.get(state, 'form.profileAccountForm.values', {})
    };
};

const Wrapped = sideBarProFileHoc(UserAccount, 'account');

export default reduxForm({
    form: 'profileAccountForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(connect(mapStateToProps, { updateProfile, getRole, closeGenericModal, openGenericModal })(Wrapped));