module.exports = {
    debugMode: true,
    server: {
        api: {
            securityApi: process.env.REACT_APP_SECURITY_API || 'https://security-dev.yogabot.app/security/',
            biometricMatrixApi: process.env.REACT_APP_BIOMETRICMATRIX_API || 'https://biometricmatrix-dev.yogabot.app/biometricmatrix/',
            progressApi: process.env.REACT_APP_PROGRESS_API || 'https://progress-dev.yogabot.app/progress/',
            sessionApi: process.env.REACT_APP_SESSIONS_API || 'https://sessions-dev.yogabot.app/session/',
            questionApi: process.env.REACT_APP_QUESTION_API || 'https://questions-dev.yogabot.app/question/',
            paymentApi: process.env.REACT_APP_PAYMENT_API || 'https://payments-dev.yogabot.app/payment/',
            schoolApi: process.env.REACT_APP_SCHOOL_API || 'https://school-dev.yogabot.app/school/',
            filesApi: process.env.REACT_APP_FILES_API || 'https://files-dev.yogabot.app/files/',
            statsApi: process.env.REACT_APP_STATS_API || 'https://stats-dev.yogabot.app/stat/',
            timeout: 20000,
            headTimeout: 2000
        }
    },
    firebase: {
        apiKey: "AIzaSyBAzGEKHZueNtT5gZuthsM6Ve92bIR1Aog",
        authDomain: "yogabot-a7a07.firebaseapp.com",
        databaseURL: "https://yogabot-a7a07.firebaseio.com",
        projectId: "yogabot-a7a07",
        storageBucket: "yogabot-a7a07.appspot.com",
        messagingSenderId: "947474339915",
        appId: "1:947474339915:web:c16406fe802a7f4f77d5ce",
        measurementId: "G-C76WK4WCCT"
    },
    cordova: {
        // active: process.env.REACT_APP_ACTIVE_CORDOVA === 'true' || false
        getActive: () => {
            const activeEnv = process.env.REACT_APP_ACTIVE_CORDOVA
            return activeEnv === 'true'
        }
    },
    growChallenge: {
        customPractices: 2
    },
    stripe: {
        publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_Nk9HUYDtUi9m34B8xIeKhhCX008Giym5jL'
    },
    player: {
        path: process.env.REACT_APP_PLAYER || 'https://dev.yogabot.app/#/player/'
    },
    inapppurchase: {
        premiumSubscription: {
            annualPayment: {
                id: 'app.yogabot.suscription.annual',
                price: {
                    USD: {
                        Android: 69.99,
                        iOS: 59.99
                    },
                    EUR: {
                        Android: 59.99,
                        iOS: 60.99
                    }
                }
            },
            monthlyPayment: {
                id: 'app.yogabot.suscription.monthly',
                price: {
                    USD: {
                        Android: 11.99,
                        iOS: 9.99
                    },
                    EUR: {
                        Android: 9.99,
                        iOS: 9.99
                    }
                }
            }
        },
        ygbPacks: {
            '25': {
                id: 'app.yogabot.ygb.50',
                price: {
                    USD: {
                        Android: 29.99,
                        iOS: 24.99
                    },
                    EUR: {
                        Android: 24.99,
                        iOS: 24.99
                    }
                }
            },
            '50': {
                id: 'app.yogabot.ygb.100',
                price: {
                    USD: {
                        Android: 59.99,
                        iOS: 49.99
                    },
                    EUR: {
                        Android: 49.99,
                        iOS: 49.99
                    }
                }
            },
            '75': {
                id: 'app.yogabot.ygb.150',
                price: {
                    USD: {
                        Android: 89.99,
                        iOS: 74.99
                    },
                    EUR: {
                        Android: 74.99,
                        iOS: 74.99
                    }
                }
            },
            '100': {
                id: 'app.yogabot.ygb.250',
                price: {
                    USD: {
                        Android: 119.99,
                        iOS: 99.99
                    },
                    EUR: {
                        Android: 99.99,
                        iOS: 99.99
                    }
                }
            }
        },
        schoolProducts: {
            '1': {
                id: 'app.yogabot.schoolProduct.1',
                price: {
                    USD: {
                        Android: 5.99,
                        iOS: 4.99
                    },
                    EUR: {
                        Android: 4.99,
                        iOS: 4.99
                    }
                }
            },
            '2': {
                id: 'app.yogabot.schoolProduct.2',
                price: {
                    USD: {
                        Android: 10.99,
                        iOS: 8.99
                    },
                    EUR: {
                        Android: 8.99,
                        iOS: 8.99
                    }
                }
            },
            '3': {
                id: 'app.yogabot.schoolProduct.3',
                price: {
                    USD: {
                        Android: 14.99,
                        iOS: 12.99
                    },
                    EUR: {
                        Android: 12.99,
                        iOS: 12.99
                    }
                }
            },
            '4': {
                id: 'app.yogabot.schoolProduct.4',
                price: {
                    USD: {
                        Android: 19.99,
                        iOS: 16.99
                    },
                    EUR: {
                        Android: 16.99,
                        iOS: 16.99
                    }
                }
            },
            '5': {
                id: 'app.yogabot.schoolProduct.5',
                price: {
                    USD: {
                        Android: 24.99,
                        iOS: 20.99
                    },
                    EUR: {
                        Android: 20.99,
                        iOS: 20.99
                    }
                }
            },
            '6': {
                id: 'app.yogabot.schoolProduct.6',
                price: {
                    USD: {
                        Android: 29.99,
                        iOS: 24.99
                    },
                    EUR: {
                        Android: 24.99,
                        iOS: 24.99
                    }
                }
            },
            '7': {
                id: 'app.yogabot.schoolProduct.7',
                price: {
                    USD: {
                        Android: 33.99,
                        iOS: 28.99
                    },
                    EUR: {
                        Android: 28.99,
                        iOS: 28.99
                    }
                }
            },
            '8': {
                id: 'app.yogabot.schoolProduct.8',
                price: {
                    USD: {
                        Android: 38.99,
                        iOS: 32.99
                    },
                    EUR: {
                        Android: 32.99,
                        iOS: 32.99
                    }
                }
            },
            '9': {
                id: 'app.yogabot.schoolProduct.9',
                price: {
                    USD: {
                        Android: 43.99,
                        iOS: 36.99
                    },
                    EUR: {
                        Android: 36.99,
                        iOS: 36.99
                    }
                }
            },
            '10': {
                id: 'app.yogabot.schoolProduct.10',
                price: {
                    USD: {
                        Android: 48.99,
                        iOS: 40.99
                    },
                    EUR: {
                        Android: 40.99,
                        iOS: 40.99
                    }
                }
            }
        }
    }
};